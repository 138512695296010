import Carousel from "../../component/carousel"
import News from "../../component/news"

const Job_NewDelhi = ()=>{
    return(
        <>
         <Carousel
         mainText={
            `We’re Hiring!`
         }
         smallText={
            `Senior Marketing Associate – MICE - New Delhi`
         }
         images={["img/slide-01.jpg","img/slide-01.jpg","img/slide-01.jpg"]}
         />
        <section className="mod-two-cols" data-lazy-load>

        <div className="">
        
        <div className="grid">
            <div className="grid__item md--two-thirds mod-two-cols--padded">
                <h3 className="heading-1"><span>Senior Marketing Associate – MICE - New Delhi</span></h3>

                
                               <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Are you a strategic thinker, creative storyteller, and results-driven marketer with a passion for the <b>MICE industry</b> (Meetings, Incentives, Conferences, and Exhibitions)? We’re looking for a <b>Senior Marketing Associate</b> to join our team in New Delhi and elevate our marketing efforts to new heights. If you have the expertise to execute high-impact strategies across organic and paid channels, and a <b>deep understanding of the MICE business</b>, we want to hear from you!
</p>
            </div>
        </div>
    </div>
</section>
<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole mod-two-cols--padded">


            <div className="grid__item ">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">What You’ll Do:</h3>
                    <div className="text--large mod-text-media__content1">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
    <li><b>Develop & Implement Marketing Strategies:</b> Plan and execute initiatives that align with business goals and client needs.</li>
    <li><b>Lead Organic Growth on Social Media:</b>  Craft engaging content, optimize engagement, and grow brand visibility through strategic organic efforts.</li>
    <li><b>Execute Paid Advertising Campaigns:</b>  Create and manage high-performing ads across Facebook, Instagram, and other platforms.</li>
    <li><b>Content Creation & Brand Storytelling:</b>  Develop compelling content that resonates with target audiences and drives conversions.</li>
    <li><b>Analytics & Performance Tracking:</b>  Measure success, analyze key metrics, and continuously optimize campaigns</li>
    <li><b>Leverage AI & Emerging Technologies:</b>  Utilize AI tools and stay ahead of industry trends to experiment with innovative marketing strategies and optimize campaigns.</li>
    <li><b>Client Campaigns & Project Management:</b>  Work on diverse client accounts, ensuring marketing initiatives deliver measurable results.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>


<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid push  lg--two-thirds md-one-whole mod-two-cols--padded">


            <div className="grid__item ">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">Who You Are:</h3>
                    <div className="text--large mod-text-media__content1">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<li><b>Experienced Marketer:</b> 4-6 years of experience in marketing, digital campaigns, and brand strategy, with a strong focus on the MICE industry.</li>
<li><b>Social Media Expert:</b>  Deep understanding of LinkedIn organic growth and paid strategies across Meta (Facebook & Instagram), particularly for MICE audiences.</li>
<li><b>Tech-Savvy Innovator:</b>  Proficient in utilizing AI tools, adaptable to new technologies, and eager to experiment with cutting-edge solutions to drive results.</li>
<li><b>Creative & Strategic:</b>  Ability to balance creativity with an analytical mindset to craft impactful MICE marketing strategies.</li>
<li><b>Strong Communicator:</b>  Excellent writing, presentation, and stakeholder management skills, with the ability to engage clients and partners effectively.</li>
<li><b>Proactive & Results-Oriented:</b>  Can take ownership, drive projects, and deliver real impact in the MICE industry.</li>
</ul>
                          
                    </div>
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole mod-two-cols--padded">


            <div className="grid__item ">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">What’s in It for You?</h3>
                    <div className="text--large mod-text-media__content1">
                    <ul style={{listStyle:"disc",marginLeft:"25px",fontSize:"20px",lineHeight:"120%",marginTop:"5px"}}>
<li>A collaborative and supportive work culture with a passionate team.</li>
<li>Opportunities to travel to exciting locations for client meetings and events.</li>
<li>Attend top MICE industry conferences to expand your knowledge and network with the best in the field.</li>
<li>Flexibility to grow your skills, experiment with new ideas, and explore creative approaches.</li>
<li>Performance-based rewards that celebrate your hard work and contributions.</li>
<li>The chance to lead impactful projects and grow your career in a dynamic environment.</li>
</ul>
                          
                    </div>
                    
                       
                </div>
            </div>
        </div>
    </div>
</article>

<article className="mod-text-media mod-text-media__scheme--Light" data-lazy-load>
    <div className="mod-text-media__wrapper grid">
        <div className="grid  lg--two-thirds md-one-whole mod-two-cols--padded">


            <div className="grid__item ">
                <div className="mod-text__content">
                    <h3 className="heading-3 mod-text__header mod-text-media__header">How to Apply:</h3>
                    <div className="text--large mod-text-media__content1">
                    <p style={{fontSize:"20px",lineHeight:"120%",marginTop:"15px"}}>Interested? Send your CV and a cover letter outlining your relevant experience in the MICE industry and why you’re a great fit for this role to <a href="mailto:info@cimglobal.net"><b><u>info@cimglobal.net</u></b></a> with the subject line: <b>Senior Marketing Associate Application – MICE</b>.
                    </p>
                          
                    </div>
                    
                       
                </div>
            </div>
        </div>
    </div>
</article>


    <News/>
        </>
    )
}

export default Job_NewDelhi