import Carousel from "../../component/carousel"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Management = () => {
    const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
    return (
        <>
            <Carousel
                mainText={
                    `Our Team`
                }
                smallText={
                    `WE are CIMGLOBAL`
                }
                images={["img/slide-01.jpg", "img/slide-01.jpg", "img/slide-01.jpg"]}
            />
     
<>
{ /*     <Button onClick={() => setSmShow(true)} className="me-2">
      
      </Button>
      <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
          Tania Ferreira 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body><p>
          General Manager - Europe<br/><br/>    
          <b>E:</b> tania@cimglobal.net
          </p></Modal.Body>
      </Modal>
      
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Tania Ferreira 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body><p>
          General Manager - Europe<br/>
          <b>E:</b> tania@cimglobal.net
          </p></Modal.Body>
      </Modal>
    </>

  

            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
                <div class="grid__item lg--nine-twelfths">

                    <ul class="grid grid--flex">
                        <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                  
                                        <img class="managemnet_img" src="/img/Prasant-Saha.jpg" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>CEO</span></div>
                                            <h3 class="text--bold text--small"> <span>Prasant Saha</span> </h3>
                                        </div>


                                    </div>
                            </article>
                        </li>
                        <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                  
                                        <img class="managemnet_img" src="/img/Indre-Rutkauskaite.jpg" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Director - Marketing</span></div>
                                            <h3 class="text--bold text--small"> <span>Indre Rutkauskaite</span> </h3>
                                        </div>
                                    </div>
                                
                            </article>
                        </li>
                       
                        <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                             
                            <a class="link link--pointing dot__wrapper mod-related__block link--zoom-image" href="#"  >
                                        <img class="managemnet_img"  onClick={() => setSmShow(true)} className="me-2" src="/img/Tania-Ferreira.jpg" />
                                        </a>
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>General Manager - Europe</span></div>
                                            <h3 class="text--bold text--small"> <span>Tania Ferreira</span> </h3>
                                        </div>
                                        
                                    </div>
                                   
                            </article>
                        </li>
                       
                       

                    </ul>
                </div>
            </div>
            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
                <div class="grid__item lg--nine-twelfths">

                    <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                    
                                        <img class="managemnet_img" src="/img/Arindam-Mookherjee.jpg" />
                                    
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Director - Association Management</span></div>
                                            <h3 class="text--bold text--small"> <span>Arindam Mookherjee</span> </h3>
                                        </div>
                                    </div>
                                                            </article>
                        </li>
                        <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                  
                                        <img class="managemnet_img" src="/img/Sushant-Saha.jpg" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Director - IT & Technology</span></div>
                                            <h3 class="text--bold text--small"> <span>Sushant Saha</span> </h3>
                                        </div>
                                    </div>
                                                            </article>
                        </li>
                    <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">

                                  
                                        <img class="managemnet_img" src="/img/Redi-Jakova.png" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Director - North America</span></div>
                                            <h3 class="text--bold text--small"> <span>Redi Jakova</span> </h3>
                                        </div>
                                    </div>
                                
                            </article>
                        </li>
                        
                       

                    </ul>
                </div>
            </div>

            <div class="grid mod-related mod-related--light mod-related--has-read-more" data-lazy-load>
                <div class="grid__item lg--nine-twelfths">

                    <ul class="grid grid--flex">
                    <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                    
                                        <img class="managemnet_img" src="/img/Aaman-Kalra.jpg" />
                                    
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Vice President</span></div>
                                            <h3 class="text--bold text--small"> <span>Aaman Kalra</span> </h3>
                                        </div>
                                    </div>
                                                            </article>
                        </li>
                       <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">
                                  
                                        <img class="managemnet_img" src="/img/Sanjeev-Mittal.jpg" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Finance</span></div>
                                            <h3 class="text--bold text--small"> <span>Sanjeev Mittal</span> </h3>
                                        </div>
                                    </div>
                                                            </article>
                        </li> 
                    <li class="grid__item md--one-third mod-related__item">
                            <article class="block text--small mod-related__block">

                                  
                                        <img class="managemnet_img" src="/img/Ravinder-Rawat.jpg" />
                                  
                                    <div class="mod-related__link">
                                        <div class="mod-related__content">
                                            <div class="block__text text--dark"> <span>Hospitality</span></div>
                                            <h3 class="text--bold text--small"> <span>Ravinder Rawat</span> </h3>
                                        </div>
                                    </div>
                                
                            </article>
                        </li>
                        
                       

                    </ul>
                </div>
            </div>
        </>
    )
}

export default Management
