import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from "./pages/home";
import Cocreate from "./pages/co-create";
import Contact from "./pages/Contact";
import ComingSoon from "./pages/ComingSoon";
import AssociationManagement from "./pages/associationManagement";
import ExhibitionManagement from "./pages/exhibitionManagement";
import Bidding from "./pages/bidding";
import Outreach from "./pages/outreach";
import Communication from "./pages/communication";
import Conference from "./pages/training/conference";
import Event from "./pages/training/event";
import Exhibition from "./pages/training/exhibition";
import Workshop from "./pages/training/workshop";
import CorporateEvents from "./pages/live/corporateEvents";
import Ourstory from "./pages/company/ourstory";
import Whoweare from "./pages/company/whoweare";
import CorporateGovernance from "./pages/company/CorporateGovernance";
import GlobalPresence from "./pages/company/globalpresence";
import LegalandCompliance from "./pages/company/legalandCompliance";
import Sustainability from "./pages/company/sustainability";
import Management from "./pages/company/management";
import Career from "./pages/company/career";
import Job_NewDelhi from "./pages/company/Job_NewDelhi";
import Clients from "./pages/company/Clients";
import Manufacturing from "./pages/company/manufacturing";
import NewsEvent from "./pages/company/NewsEvent";

import AFITAWCCAConference from "./pages/CaseStudies/AFITAWCCAConference";
import CaseStudiesAll from "./pages/CaseStudies";
import CaseStudies from "./pages/CaseStudies/caseStudies";
import ASIAFORGEMeetingandForgetechIndiaExhibition from "./pages/CaseStudies/ASIAFORGEMeetingandForgetechIndiaExhibition";

import NewsAll from "./pages/news";

import FisitaWorldAutomotiveCongress from "./pages/CaseStudies/FisitaWorldAutomotiveCongress";
import SocialFootprintsNationalCsrAwardsSummit from "./pages/CaseStudies/SocialFootprintsNationalCsrAwardsSummit";
import WindergyIndiaConferenceandExhibition from "./pages/CaseStudies/WindergyIndiaConferenceandExhibition";
import CONCRETEConferenceandExhibition from "./pages/CaseStudies/CONCRETEConferenceandExhibition";
import ReachingOver200ThousandThroughSocialMediaCampaign from "./pages/CaseStudies/ReachingOver200ThousandThroughSocialMediaCampaign";
import AnnualMeetingoftheInternationalSocietyofBloodPurification from "./pages/CaseStudies/AnnualMeetingoftheInternationalSocietyofBloodPurification";
import InternationalSTEMFest from "./pages/CaseStudies/InternationalSTEMFest";
import WWWConference from "./pages/CaseStudies/WWWConference";
import GhaScaiShockMena from "./pages/news/GhaScaiShockMena";
import IndiaAssociationCongress from "./pages/news/IndiaAssociationCongress";
import GlobalHealthForum from "./pages/news/GlobalHealthForum";
import NewsPage from "./pages/news/newspage";
import CasePage from "./pages/CaseStudies/casepage";
import CaseStudyGlobalHealthForum from "./pages/CaseStudies/CaseStudyGlobalHealthForum";
import CaseStudyGhaScaiShockMena from "./pages/CaseStudies/CaseStudyGhaScaiShockMena";
import TheBestofSCCMCriticalCareCongressinAbuDhabi from "./pages/CaseStudies/TheBestofSCCMCriticalCareCongressinAbuDhabi";
import TermsandCondition from "./pages/Terms/TermsandCondition";
import CookiePolicyPrivacy from "./pages/Terms/CookiePolicyPrivacy";
 
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
        {
          index:true,
          element: <Home/>,
        },
        {
          path:'/GlobalPresence',
          element: <GlobalPresence/>,
        },
        {
          path:'/casestudies',
          element: <CaseStudies/>,
        },
        {
          path:'/Cocreate',
          element: <Cocreate/>,
        },
        {
          path:'/Contact',
          element: <Contact/>,
        },
        {
          path:'/ComingSoon',
          element: <ComingSoon/>,
        },
        {
          path:'/associationManagement',
          element: <AssociationManagement/>,
        },
        {
          path:'/exhibitionManagement',
          element: <ExhibitionManagement />,
        },
        {
          path:'/bidding',
          element: <Bidding/>,
        },
        {
          path:'/Clients',
          element: <Clients/>,
        },
        {
          path:'/outreach',
          element: <Outreach/>,
        },
        {
          path:'/communication',
          element: <Communication/>,
        },
        {
          path:'/conference',
          element: <Conference/>,
        },
        {
          path:'/event',
          element: <Event/>,
        },
        {
          path:'/exhibition',
          element: <Exhibition/>,
        },
        {
          path:'/workshop',
          element: <Workshop/>,
        },
        {
          path:'/corporateEvents',
          element: <CorporateEvents/>,
        },
        //----------------------------------------
        {
          path:'/ourstory',
          element: <Ourstory/>,
        },
        {
          path:'/whoweare',
          element: <Whoweare/>,
        },
        {
          path:'/CorporateGovernance',
          element: <CorporateGovernance/>,
        },
        {
          path:'/legalandCompliance',
          element: <LegalandCompliance/>,
        },
        {
          path:'/sustainability',
          element: <Sustainability/>,
        },
        {
          path:'/management',
          element: <Management/>,
        },
        {
          path:'/career',
          element: <Career/>,
        },
        {
          path:'/Job_NewDelhi',
          element: <Job_NewDelhi/>,
        },
        {
          path:'/manufacturing',
          element: <Manufacturing/>,
        },
        {
          path:'/newsEvent',
          element: <NewsEvent/>,
        },
        {

          path:'/AFITAWCCAConference',
          element: <AFITAWCCAConference/>,
        },
        {
          path:'/CaseStudiesAll',
          element: <CaseStudiesAll/>,
        },
        {
          path:'/NewsAll',
          element: <NewsAll/>,
        },
               
    
        {
          path:'/FisitaWorldAutomotiveCongress',
          element: <FisitaWorldAutomotiveCongress/>,
        },
        {
          path:'/SocialFootprintsNationalCsrAwardsSummit',
          element: <SocialFootprintsNationalCsrAwardsSummit/>,
        },
        {
          path:'/ASIAFORGEMeetingandForgetechIndiaExhibition',
          element: <ASIAFORGEMeetingandForgetechIndiaExhibition/>,
        },
        {
          path:'/WindergyIndiaConferenceandExhibition',
          element: <WindergyIndiaConferenceandExhibition/>,
        },
        {
          path:'/CONCRETEConferenceandExhibition',
          element: <CONCRETEConferenceandExhibition/>,
        },
        {
          path:'/ReachingOver200ThousandThroughSocialMediaCampaign',
          element: <ReachingOver200ThousandThroughSocialMediaCampaign/>,
        },
        {
          path:'/AnnualMeetingoftheInternationalSocietyofBloodPurification',
          element: <AnnualMeetingoftheInternationalSocietyofBloodPurification/>,
        },
        {
          path:'/InternationalSTEMFest',
          element: <InternationalSTEMFest/>,
        },
        {
          path:'/WWWConference',
          element: <WWWConference/>,
        },
        {
          path:'/GhaScaiShockMena',
          element: <GhaScaiShockMena/>,
        },
        {
          path:'/IndiaAssociationCongress',
          element: <IndiaAssociationCongress/>,
        },
        {
          path:'/GlobalHealthForum',
          element: <GlobalHealthForum/>,
        },
        {
          path:'/NewsPage/:blogId/:slug/:case',
          element: <NewsPage/>,
        },
    
        {
          path:'/CasePage',
          element: <CasePage/>,
        },
        {
          path:'/CaseStudyGlobalHealthForum',
          element: <CaseStudyGlobalHealthForum/>,
        },
        {
          path:'/CaseStudyGhaScaiShockMena',
          element: <CaseStudyGhaScaiShockMena/>,
        },
        {
          path:'/TheBestofSCCMCriticalCareCongressinAbuDhabi',
          element: <TheBestofSCCMCriticalCareCongressinAbuDhabi/>,
        },

        {
          path:'/TermsandCondition',
          element: <TermsandCondition/>,
        },
       
        {
          path:'/CookiePolicyPrivacy',
          element: <CookiePolicyPrivacy/>,
        },


        ],

    },
  ]);
export default router