import logo from './logo.svg';
import './App.css';
import Header from './component/header';
import { Outlet } from 'react-router-dom';
import Footer from './component/footer';
import { Useprovider } from "./stateManagementAPi";
import { useState } from 'react';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import tEn from "./locales/en/translation.json";
import tPr from "./locales/pr/translation.json";

function App() {
  const [data, setData] = useState("");
  const lang = localStorage.getItem("lang");
  if (lang == null) {
    localStorage.setItem("lang", "en");
  }
  
  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: tEn,
      },
      pr: {
        translation: tPr,
      }
    },
    lng: lang, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

  return (
    <>
    <Useprovider.Provider value={{ data: [data, setData] }}>
    <Header/>
    <div id="detail">
            <Outlet />
            </div>
            <Footer/>
      </Useprovider.Provider>
    {/* <Header/>
    <div id="detail">
        <Outlet />
      </div>
      <Footer/> */}
    </>
  );
}

export default App;